import * as Yup from 'yup';
import {FormNameEnum} from 'enums/form';
import {errorMessages} from 'utils/constants';
import {IFileTypeRecognition, IFileVersion, IInputFile} from 'models';
import {getIsBeforeDate} from 'utils/common';

export const editResourceBaseMetadataValidationsShape = {
  [FormNameEnum.displayName]: Yup.string().required(errorMessages.requiredWithInputName('a Display Name')),
  [FormNameEnum.fileType]: Yup.number().required(errorMessages.requiredWithFieldName('a File Type')),
  [FormNameEnum.gradeLevels]: Yup.array().of(Yup.number()),
  [FormNameEnum.pillars]: Yup.array().of(Yup.number()),
  [FormNameEnum.competencies]: Yup.array().of(Yup.number()),
  [FormNameEnum.languages]: Yup.array().of(Yup.number()).required('You should select at least one language!'),
  [FormNameEnum.keyTerms]: Yup.string().nullable(),
  [FormNameEnum.isAccessibleToStudents]: Yup.string().required(
    errorMessages.requiredWithFieldName('a Accessible to students')
  ),
  [FormNameEnum.briefDescription]: Yup.string().nullable(),
  [FormNameEnum.programs]: Yup.array().of(Yup.number()),
  [FormNameEnum.knowledgeSkillsAndAbilities]: Yup.array().of(Yup.number()),
  [FormNameEnum.expirationDate]: Yup.date()
    .nullable()
    .typeError('Expected date format: (mm/dd/yyyy)')
    .test('is-date-earlier-than-now', 'The selected date should be in the future.', function () {
      const {expirationDate} = this.parent;

      // If the datepicker is cleaned up, then validation is true
      if (expirationDate === null) {
        return true;
      }

      function isBefore(expirationDate: Date): boolean {
        const dateIsBeforeToday = getIsBeforeDate(expirationDate);

        if (dateIsBeforeToday) {
          return false;
        }
        return true;
      }

      if (expirationDate instanceof Date) {
        return isBefore(expirationDate);
      }
    }),
  [FormNameEnum.learningObjectives]: Yup.string().nullable(),
  [FormNameEnum.time]: Yup.number()
    .nullable()
    .min(0, 'Time should be a positive number!')
    .max(1440, 'Time should be a positive number smaller than 1440!'),
  [FormNameEnum.briefDescription]: Yup.string().nullable(),
  [FormNameEnum.webLink]: Yup.string()
    .nullable()
    .test('is-web-link', errorMessages.requiredWithInputName('a Web Link'), function () {
      const {fileTypeRecognition, webLink} = this.parent;

      if (fileTypeRecognition.isWebLink) {
        return !!webLink;
      }

      return true;
    })
    .url('Web link must be a valid url!'),
  [FormNameEnum.fileVersion]: Yup.string().nullable(),
  [FormNameEnum.hasUploadPermissions]: Yup.boolean().test(
    'is-checked',
    'You should accept upload file permissions!',
    function () {
      const {file, hasUploadPermissions} = this.parent;
      const isHasUploadPermissionsTruthy = hasUploadPermissions === true;

      if (file) {
        return isHasUploadPermissionsTruthy;
      }

      return true;
    }
  ),
  [FormNameEnum.canEdit]: Yup.boolean(),
};
export interface IEditFileMetadataFormBaseValues {
  [FormNameEnum.fileType]: number;
  [FormNameEnum.displayName]: string;
  [FormNameEnum.gradeLevels]: number[];
  [FormNameEnum.competencies]: number[];
  [FormNameEnum.languages]: number[];
  [FormNameEnum.keyTerms]: string;
  [FormNameEnum.isAccessibleToStudents]: string;
  [FormNameEnum.briefDescription]: string;
  [FormNameEnum.programs]: number[];
  [FormNameEnum.pillars]: number[];
  [FormNameEnum.knowledgeSkillsAndAbilities]: number[];
  [FormNameEnum.expirationDate]: Date | string;
  [FormNameEnum.learningObjectives]: string;
  [FormNameEnum.shouldDisableFileType]: boolean;
  [FormNameEnum.time]?: number;
  [FormNameEnum.webLink]?: string;
  [FormNameEnum.file]?: IInputFile;
  [FormNameEnum.fileVersion]?: string;
  [FormNameEnum.hasUploadPermissions]?: boolean;
  [FormNameEnum.canEdit]?: boolean;
  [FormNameEnum.fileTypeRecognition]?: IFileTypeRecognition;
  [FormNameEnum.versions]?: IFileVersion[];
}

export const editFileMetadataValidationSchema = Yup.object().shape({
  ...editResourceBaseMetadataValidationsShape,
});
