import {IEditFileMetadataFormBaseValues} from '../components/FilesEditResourceForm/config';

export const mapResourceMetadata = ({
  keyTerms,
  learningObjectives,
  expirationDate,
  languages,
  programs,
  pillars,
  gradeLevels,
  isAccessibleToStudents,
  competencies,
  knowledgeSkillsAndAbilities,
  time,
}: IEditFileMetadataFormBaseValues) => ({
  isAccessibleToStudents: isAccessibleToStudents,
  keyTerms: keyTerms ?? '',
  learningObjectives: learningObjectives ?? '',
  accessExpirationDate: expirationDate ?? null,
  languages: languages ?? [],
  competencies: competencies ?? [],
  pillars: pillars ?? [],
  programs: programs ?? [],
  gradeLevels: gradeLevels ?? [],
  ksAs: knowledgeSkillsAndAbilities ?? [],
  lengthInMinutes: time == 0 || time == undefined ? null : time,
});
